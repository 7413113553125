<template>
  <div class="bitts-steps">
    <steps v-if="!stepsLinked" :initial="stepsCountOffset" :size="stepSize">
      <a-step
        v-for="(step, index) in steps"
        :key="index"
        :title="step.title"
        :description="step.description"
        :status="step.status"
      >
        <template v-if="step.status === 'finish'" #icon>
          <FontAwesomeIcon
            :icon="['fak', 'check-circle']"
            :style="{ height: '24px', width: '24px', color: 'currentColor' }"
            class="pb-2"
          />
        </template>
      </a-step>
    </steps>
    <steps v-else :size="stepSize">
      <a-step
        v-for="(step, index) in steps"
        :key="index"
        :title="step.title"
        :description="step.description"
        :status="step.status"
        class="cursor-pointer"
        @click="$router.push({ name: step.route })"
      >
        <template v-if="step.status === 'finish'" #icon>
          <FontAwesomeIcon
            :icon="['fak', 'check-circle']"
            :style="{ height: '24px', width: '24px', color: 'currentColor' }"
            class="pb-2"
          />
        </template>
      </a-step>
    </steps>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Steps } from 'ant-design-vue';

export default {
  name: 'BittsSteps',
  components: {
    'a-step': Steps.Step,
    Steps,
    FontAwesomeIcon,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    stepsLinked: {
      type: Boolean,
      default: false,
    },
    stepsCountOffset: {
      type: Number,
      default: 0,
    },
    stepSize: {
      type: String,
      default: 'small',
    },
  },
};
</script>

<style lang="pcss">
.bitts-steps {
  .ant-steps {
    font-family: inherit;
  }
  /* Icon */
  .ant-steps.ant-steps-small .ant-steps-item-icon {
    font-family: inherit;
  }
  .ant-steps .ant-steps-item-icon .ant-steps-icon {
    position: inherit;
  }
  .ant-steps
    .ant-steps-item-finish.ant-steps-item-custom
    .ant-steps-item-icon
    > .ant-steps-icon {
    @apply text-brand-blue;
  }
  .ant-steps
    .ant-steps-item-custom
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    @apply h-24 w-24;
  }
  .ant-steps-item-icon {
    @apply inline-flex items-center justify-center;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    @apply mr-8;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    @apply text-brand-blue border-brand-blue h-24 w-24;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    @apply border-brand-blue;
    background-color: transparent;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    @apply text-brand-blue font-bold;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    @apply border-neutral-border;
    background-color: transparent;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    @apply text-neutral-text-placeholder font-bold;
  }

  /* Line separator */
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    @apply bg-brand-blue;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    @apply bg-neutral-border;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    @apply bg-neutral-border;
  }

  /* Title */
  .ant-steps-item-title {
    @apply leading-6;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    @apply text-neutral-text-strong text-base;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    @apply text-neutral-text-strong text-base;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    @apply text-neutral-text-strong text-base;
  }

  /* Description */
  .ant-steps-item-description {
    @apply leading-6;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    @apply text-neutral-text text-sm;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    @apply text-neutral-text text-sm;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    @apply text-neutral-text text-sm;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    max-width: 240px;
  }

  /* fix positioning issue between FF and Chrome */
  .ant-steps-item-icon > .ant-steps-icon {
    top: 0px;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    top: 0px;
  }
}
@-moz-document url-prefix() {
  .bitts-steps .ant-steps-item-icon > .ant-steps-icon {
    top: -1px;
  }
}
</style>
